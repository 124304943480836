.VerticalSlider {
  height: 212px;
  width: 2.5px;
  /* background-color: #ccc; */
}

.HorizontalSlider {
  width: 100%;
  height: 2.5px;
  background-color: #ccc;
}

.SubtitleBox {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.05em;
  font-family: var(--font-family-second);
}

.ThumbSliderHorizontal {
  background: #50ffff;
  height: 9px;
  width: 9px;
  margin-top: -3px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3 !important;
}

.ThumbSliderHorizontalBlack {
  background: #4d4c4c;
  height: 9px;
  width: 9px;
  margin-top: -3px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3 !important;
}

.ThumbSliderHorizontalBlack:focus {
  outline: none;
}

.ThumbSliderHorizontal:focus {
  outline: none;
}

.ThumbSlider {
  background: #50ffff;
  height: 9px;
  width: 9px;
  margin-left: -3px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3 !important;
}

.ThumbSlider:focus {
  outline: none;
}

.OptionSlider {
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  font-family: var(--font-family-second);
  letter-spacing: 0.09em;
}
