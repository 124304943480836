.TextModal {
    font-weight: 300;
    font-size: 24.13px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
  
  .TitleModal {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 38px;
    letter-spacing: 0.05em;
    width: 100%;
  
    text-transform: uppercase;
    font-family: var(--font-family-second);
  }
  
  .Modal > :global.modal-content {
    border-radius: 0px;
    border: 0.837802px solid #000000;
  }
  
  .ModalBody {
    border: none;
  }
  
  .ModalHeader > :global.modal-title {
    width: 100%;
    border-bottom: 1px solid black;
  }
  
  .ModalHeader {
    border: none;
  }
  
  .ModalFooter {
    border: none;
  }
  
  .CloseBox {
    position: absolute;
    right: 18px;
    top: 10px;
    cursor: pointer;
  }

  .ButtonConfirm {
    padding: 6px 16px;
    border: 1px solid #000000;
    border-radius: 96px;
    font-weight: 400;
    color: #000000;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.05em;
    cursor: pointer;
    font-family: var(--font-family-second);
  }

  .ButtonConfirm:visited {
    color: #000000;
  }
  
  .ButtonConfirm:hover {
    border: 1px solid #000000;
    box-shadow: 2px 2px 0px #000000;
    border-radius: 96px;
  }
  
  .ButtonConfirmDisabled {
    padding: 6px 16px;
    border: 1px solid #a0a0a0;
    border-radius: 96px;
    color: #a0a0a0;
    text-transform: uppercase;
    font-family: var(--font-family-second);
    letter-spacing: 0.05em;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
  
  .ButtonCancel {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
    cursor: pointer;
    font-family: var(--font-family-second);
  }
  
  .Buttons {
    position: absolute;
    bottom: 20px;
    display: flex;
    width: calc(100% - 36px);
    justify-content: space-between;
    align-items: center;
  }
  
  