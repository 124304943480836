.TextModal {
  font-weight: 300;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.02em;
}

.TitleModal {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  width: 100%;
  text-transform: uppercase;
  font-family: var(--font-family-second);
}

.Modal > :global.modal-content {
  border-radius: 0px;
  border: 0.837802px solid #000000;
}

.ModalBody {
  border: none;
}

.ModalHeader > :global.modal-title {
  width: 100%;
  border-bottom: 1px solid black;
}

.ModalHeader {
  border: none;
}

.ModalFooter {
  border: none;
}

.ButtonConfirm {
  padding: 6px 16px;
  border: 1px solid #000000;
  border-radius: 96px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  cursor: pointer;
  font-family: var(--font-family-second);
}

.ButtonConfirm:hover {
  border: 1px solid #000000;
  box-shadow: 2px 2px 0px #000000;
  border-radius: 96px;
}

.ButtonCancel {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  cursor: pointer;
  font-family: var(--font-family-second);
}

.Crea {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  font-family: var(--font-family-second);
  color: #000000;
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
}
