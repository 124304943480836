.LogoCard {
  border: 1px solid #000;
  padding: 16px;
  font-family: var(--font-family-second);
  margin-bottom: 16px;
}

.ImgLogo {
  border: 0.75px solid #a0a0a0;
}

.InfoLogo {
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  margin-top: 2px;
  color: #000000;
  min-height: 20px;
}

.Delete, .Download {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  cursor: pointer;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #000000;
}

.Star{
  top: 5px;
  right: 0px;
  position: absolute;
  cursor: pointer;
}
