.RecordBox {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.RecordBoxNormal {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.TimeRecording {
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.12em;
  margin-top: 5px;
  color: black;
  font-family: var(--font-family-second);
  position: relative;
}

.TimeRecordingDot {
  position: absolute;
  top: 0;
  left: -10px;
}

.TimeRecordingDot circle {
  fill: red;
}

.Replay.ReplayHidden {
  pointer-events: none;
  opacity: 0;
}

.Replay {
  opacity: 1;
  display: flex;
  align-items: center;
  margin-top: 16px;
  flex-direction: row-reverse;
  position: relative;
}

.ReplayIconTool {
  position: absolute;
  right: 5px;
  top: 0px;
}

.Replay > .ReplayIcon:hover + .ReplayText {
  opacity: 1;
}

.Replay > .ReplayIconTool:hover + .ReplayText {
  opacity: 1;
}

.Replay > .ReplayIcon:hover + .ReplayTextPro {
  opacity: 1;
}

.Replay > .ReplayIconTool:hover + .ReplayTextPro {
  opacity: 1;
}

.icons > span:hover + div {
  opacity: 1;
}

.ReplayText {
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.09em;
  font-family: var(--font-family-second);
  opacity: 0;
  position: absolute;
  left: -65px;
}

.ReplayTextPro {
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.09em;
  font-family: var(--font-family-second);
  opacity: 0;
  position: absolute;
  left: -100px;
  top: 5px;
}

@media only screen and (min-width: 150px) and (max-width: 1000px) {
  .RecordBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .ReplayText {
    display: none;
  }

  .ReplayTextPro {
    display: none;
  }

  .ReplayIcon {
    margin-left: 24px;
  }
}

.RecordMic * {
  cursor: pointer;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.ContainerIconAndTime {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.Button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.Button:hover > .TextButton {
  opacity: 1;
  margin-right: 16px;
}

.TextButton {
  opacity: 0;
  margin-right: 16px;
  text-transform: uppercase;
  font-family: var(--font-family-second);
  font-weight: 400;
  letter-spacing: 0.09em;
  font-size: 13px;
  margin-top: -20px !important;
  line-height: 21px;
}

@media only screen and (min-width: 150px) and (max-width: 1000px) {
  /* On mobile avoid open image in another tab... */
  .RecordMic img {
    pointer-events: none;
  }

  .RecordBoxNormal {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
  }

  .Replay {
    opacity: 1;
    margin-top: 0px;
    position: absolute;
    right: 20px;
    left: 80px;
    top: 25px;
    align-items: unset;
    display: block;
  }

  .ReplayMobileTool {
    top: 5px;
    left: 35px;
  }

  .TextButton {
    display: none;
  }

  .ReplayIconTool {
    left: 0px !important;
  }
}

.RecordImageContainer {
  position: relative;
}


.Shine > svg > g > circle {
  /* stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: progress 3s linear forwards infinite; */
  /* content: "";
  top: 0;
  transform: translateX(100%);
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  animation: slide 2s infinite 3s;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); */
}

/* @keyframes progress {
  to {
    stroke-dashoffset: 0;
  }
} */



@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@media print {
  .RecordBox {
    display: none;
  }
  .RecordBoxNormal {
    display: none;
  }
}