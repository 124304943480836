.svg-container {
  background: linear-gradient(#555555, #ccc);

}

.svg-container svg g {
  /* transition: transform 0.1s linear; */
}

.svg-container svg path {
  /* transition: transform 0.1s linear; */
}

.svg-container svg clipPath {
  /* transition: transform 0.1s linear; */
}

@media only screen and (min-width: 150px) and (max-width: 1000px) {
  .svg-container {
    background: none;
  }
}

@media print {
  .svg-container {
    background: transparent;
  }
}
