.TitleModal {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  width: 100%;
  text-transform: uppercase;
  font-family: var(--font-family-second);
}

.Modal > :global.modal-content {
  border-radius: 0px;
  border: 1px solid #000000;
  font-family: var(--font-family-second);
}

.ModalBody {
  border: none;
  overflow: auto;
  max-height: 500px;
}
.OptionsBlock svg {
  width: 22px;
}

.ModalHeader > :global.modal-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  letter-spacing: 0.05em;
  text-decoration-line: underline;

  color: #000000;
}

.ModalHeader {
  border-bottom: 1px solid #000000;
}

.ModalFooter {
  border-top: 1px solid #000000;
}

.Label {
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  /* identical to box height, or 211% */
  font-family: var(--font-family-second);
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-bottom: 1px solid #000;
  color: #000000;
  width: 100%;
}

.ActiveAgeRange {
  background-color: white;
  padding: 3px;
  border: 1px solid #000;
  border-radius: 50%;
}

.AgeRangeItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 600;
  margin-right: 20px;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.12em;
  font-family: var(--font-family-second);
}

.AgeRange {
  padding: 3px;
  border: 1px solid transparent;
}

.ButtonConfirm {
  padding: 6px 16px;
  border: 1px solid #000000;
  border-radius: 96px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  cursor: pointer;
  font-family: var(--font-family-second);
}

.ButtonConfirm:hover {
  border: 1px solid #000000;
  box-shadow: 2px 2px 0px #000000;
  border-radius: 96px;
}

.ButtonConfirmDisabled {
  padding: 6px 16px;
  border: 1px solid #a0a0a0;
  border-radius: 96px;
  color: #a0a0a0;
  text-transform: uppercase;
  font-family: var(--font-family-second);
  letter-spacing: 0.05em;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.ButtonCancel {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  cursor: pointer;
  font-family: var(--font-family-second);
}

.LabelSwitch {
  font-family: var(--font-family-second);
  display: flex;
  align-items: center;
  font-size: 16px;
}

.ShowDidascalia {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* font-size: 18px; */
  line-height: 25px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
}
