.SubtitleBox {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.05em;
  font-family: var(--font-family-second);
}

.SubtitleBoxDisabled{
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.05em;
  font-family: var(--font-family-second);
  color: #aaaaaa;
}

.AgeRangeItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 600;
  margin-right: 20px;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.12em;
  font-family: var(--font-family-second);
}

.ActiveAgeRange {
  background-color: white;
  padding: 3px;
  border: 1px solid #000;
  border-radius: 50%;
}

.AgeRange {
  padding: 3px;
  border: 1px solid transparent;
}

.MaxLength {
  text-align: right;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.05em;
  font-family: var(--font-family-second);
}
