.InstallationCard {
  border: 1px solid #000;
  padding: 16px;
  font-family: var(--font-family-second);
  margin-bottom: 16px;
}
.InstallationCard a {
  color: inherit;
  text-decoration: none;
}

.Name,
.Etichetta {
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #000000;
  min-height: 20px;
}

.Years {
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #000000;
  min-height: 20px;
  display: flex;
  align-items: center;
}

.Token {
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  margin-top: 16px;
  color: #000000;
  overflow-wrap: break-word;
}

.Token a {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Delete,
.Edit {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  cursor: pointer;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #000000;
}

.CardInfo {
  width: 100%;
  padding-bottom: 16px;
}
