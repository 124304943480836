.SharingWith {
  font-family: var(--font-family-second);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-bottom: 1px solid #000000;
}

.SharingItem {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.09em;
  font-family: var(--font-family-second);
}

.InstructionCopy {
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #666666;
}

.CurrentUrl {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.09em;
  margin-top: 10px;
  font-family: var(--font-family-second);
  padding-bottom: 5px;
  border-bottom: 0.5px solid #A0A0A0;
}
