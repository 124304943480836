.TextModal {
  font-weight: 300;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.02em;
}

.TitleModal {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  width: 100%;
  text-transform: uppercase;
  font-family: var(--font-family-second);
}

.Modal >  :global.modal-content {
  border-radius: 0px;
  border: 0.837802px solid #000000;
}

.ModalBody {
  border: none;
}

.ModalHeader > :global.modal-title {
  width: 100%;
  border-bottom: 1px solid black;
}

.ModalHeader {
  border: none;
}

.ModalFooter{
  border: none;
}

@media print {
  .Modal {
    display: none;
  }
}