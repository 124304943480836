.SloganMilano {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  font-family: var(--font-family-second);
}

.SubSlogan {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  margin-top: 4px;
  font-family: var(--font-family-second);
}

.MenuItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: black;
  font-family: var(--font-family-second);
}

.MenuItem:hover, .MenuItemActive:hover {
  color: black;
}

.MenuItemActive {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  text-decoration: underline;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: black;
  font-family: var(--font-family-second);
}

.ButtonLogout {
  padding: 4px 20px;
  border: 1px solid #000000;
  border-radius: 96px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
  background-color: white;
  line-height: 20px;
  letter-spacing: 0.05em;
  cursor: pointer;
  height: fit-content;
  font-family: var(--font-family-second);
  background-color: white;
}
