@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

:root {
  --font-family-first: "Source Serif Pro", serif;
  --font-family-second: "Source Sans Pro", sans-serif;
}

body,
html,
#root {
  margin: 0;
  font-family: var(--font-family-first);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: black;
}

.pointer {
  cursor: pointer;
}

.links {
  position: absolute;
  bottom: 55px;
  display: flex;
}

.flex-1 {
  flex: 1 1;
}

.links > div {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  font-family: var(--font-family-second);
}

.link {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
}

.input-name {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  /* or 133% */
  border: none;
  letter-spacing: 0.05em;
  white-space: pre-wrap;
  resize: none;
  height: 50px;
  max-height: 50px;
}

.input-name:disabled {
  background-color: white;
}

.input-name:disabled::placeholder {
  opacity: 0.4;
}

.input-name:focus-visible {
  outline: none;
}

.input-name-big {
  font-weight: 300;
  font-size: 24.13px;
  line-height: 32px;
  /* or 133% */
  border: none;
  letter-spacing: 0.05em;
  white-space: pre-wrap;
  resize: none;
  height: 50px;
  max-height: 50px;
}

.input-name-big:disabled {
  background-color: white;
}

.input-name-big:disabled::placeholder {
  opacity: 0.4;
}

.input-name-big:focus-visible {
  outline: none;
}

.button-inizia {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 96px;
  width: 100%;
  text-align: center;
  display: flex;
  height: 38px;
  align-items: center;
  justify-content: center;
}

.slogan-mobile {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

/* For react-slider */

.track-0 {
  width: 100% !important;
}

.track-1 {
  display: none !important;
}

.slide.slide-1 {
  background: #ccc !important;
  z-index: 2;
  width: 2.5px;
}

.slide.slide-0 {
  background: #50ffff !important;
  z-index: 2;
  width: 2.5px;
}

.slide-hor.slide-hor-1 {
  background: #ccc !important;
  z-index: 2;
  height: 2.5px;
}

.slide-hor.slide-hor-0 {
  background: #50ffff !important;
  z-index: 2;
  height: 2.5px;
}

.slide-long.slide-long-0 {
  background: #4d4c4c !important;
  z-index: 2;
  height: 2.5px;
}

.slide-long.slide-long-1 {
  background: #ccc !important;
  z-index: 2;
  height: 2.5px;
}

.modal-backdrop {
  background-color: rgba(255, 255, 255);
  mix-blend-mode: normal;
}

.modal-backdrop.show {
  opacity: 0.7;
}

@media only screen and (min-width: 150px) and (max-width: 1000px) {
  .links {
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    font-family: var(--font-family-second);
  }
}

.barlow {
  font-family: "Barlow", sans-serif;
}

.dimensions__single-value {
  font-size: 13px;
  line-height: 20px;
  color: black !important;
  opacity: 1;
  font-family: var(--font-family-second);
  letter-spacing: 0.05em;
}

.dimensions__option {
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: black;
  font-family: var(--font-family-second);
  letter-spacing: 0.05em;
}

.dimensions__indicator {
  color: black !important;
  opacity: 1;
}

.dimensions__indicator-separator {
  display: none;
}

.dimensions__control {
  background-color: none !important;
  border: 1px solid black !important;
  border-radius: 0 !important;
  color: black !important;
}

.form-check-input:checked {
  background-color: black;
  border-color: black;
}

.pagination {
  font-family: var(--font-family-second);
}

.page-link.active,
.active > .page-link {
  background-color: black;
  border: black;
}

.height-100vh {
  height: 100vh;
}

@media print {
  .modal-backdrop.show {
    display: none;
  }
}
@media print {
  .animated-logo-links {
    display: none;
  }
}
