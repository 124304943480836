.TextModal {
  font-weight: 300;
  font-size: 24.13px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

.TitleModal {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 38px;
  letter-spacing: 0.05em;
  width: 100%;

  text-transform: uppercase;
  font-family: var(--font-family-second);
}

.Modal > :global.modal-content {
  border-radius: 0px;
  border: 0.837802px solid #000000;
}

.ModalBody {
  border: none;
}

.ModalHeader > :global.modal-title {
  width: 100%;
  border-bottom: 1px solid black;
}

.ModalHeader {
  border: none;
}

.ModalFooter {
  border: none;
}

.CloseBox {
  position: absolute;
  right: 18px;
  top: 10px;
  cursor: pointer;
}
