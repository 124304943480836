.Question {
  font-family: var(--font-family-second);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-bottom: 1px solid #000000;
}

.BoxContainerStep {
  height: 109px;
}

.BoxPersonalization {
  position: absolute;
  bottom: 0px;
  background-color: white;
  z-index: 2;
  left: 0px;
  right: 0px;
  width: 100%;
  padding: 0px 20px 20px 20px;
}

.ButtonsPersonalization {
  position: absolute;
  bottom: 20px;
  display: flex;
  width: calc(100% - 40px);
  font-family: var(--font-family-second);
  justify-content: space-between;
  align-items: center;
}

.ButtonCancel {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  cursor: pointer;
  font-family: var(--font-family-second);
}

.ButtonNextStep {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 96px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  height: 38px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: var(--font-family-second);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.ButtonNextStepDisabled {
  background: #ffffff;
  border: 1px solid #a0a0a0;
  border-radius: 96px;
  color: #a0a0a0;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  height: 38px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: var(--font-family-second);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.ButtonConfirm {
  border: 1px solid #000000;
  border-radius: 96px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 16px;
  padding-right: 16px;
  height: 38px;
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 20px;
  cursor: pointer;
  font-family: var(--font-family-second);
}

.ButtonConfirmDisabled {
  border: 1px solid #a0a0a0;
  border-radius: 96px;
  color: #a0a0a0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
  line-height: 20px;
  height: 38px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.TitleBox {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  font-family: var(--font-family-second);
}

.DividerMobile {
  height: 1px;
  width: calc(100% + 22px);
  margin-left: -22px;
  background: black;
  position: absolute;
  z-index: 100;
}

.SliderContainer {
  height: 58px;
  position: relative;
}

.SelectAgeText {
  position: absolute;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  left: 0;
  right: 0;
  letter-spacing: 0.09em;
  top: 27px;
}

.BoxAnimation {
  transition: transform 350ms ease-in;
}
.BoxOpening {
  display: initial;
  transform: translateY(400px);
}
.BoxOpen {
  display: initial;
  transform: translateY(0);
}
.BoxClose {
  display: none;
  transform: translateY(0px);
}
.BoxClosing {
  display: initial;
  transform: translateY(400px);
}

.Reset {
  cursor: pointer;
  align-items: center;
  display: flex;
}
.ResetText {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: -5px;
  letter-spacing: 0.05em;
}
.ResetIcon::after {
  margin-right: 10px;
  content: url('../../assets/reset.svg');
}