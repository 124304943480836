.Title {
    font-weight: 400;
    font-size: 50px;
    line-height: 54px;
    letter-spacing: -0.02em;
  }
  
  .Subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    font-family: var(--font-family-second);
    letter-spacing: 0.05em;
    text-decoration-line: underline;
  }
  
  .Text {
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    font-family: var(--font-family-second);
    letter-spacing: 0.05em;
  }
  
  @media only screen and (min-width: 150px) and (max-width: 1000px) {
    .Title {
      font-weight: 400;
      font-size: 50px;
      line-height: 54px;
      letter-spacing: -0.02em;
      margin-top: 100px;
    }
  }
  