.BoxRightDesktop {
  position: fixed;
  border: 1px solid #000000;
  margin-bottom: 30px;
  padding: 18px;
  background-color: white;
  width: 312px;
  max-width: 312px;
  max-width: 312px;
  height: 610px;
  margin-top: 35px;
  z-index: 2;
}

.BoxRightDesktopPro{
  position: fixed;
  border: 1px solid #000000;
  margin-bottom: 30px;
  padding: 18px;
  margin-top: 30px;
  background-color: white;
  width: 312px;
  max-width: 312px;
  max-width: 312px;
  height: 690px;
  margin-top: 35px;
  z-index: 2;
}

.TitleBox {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  width: 100%;
  font-family: var(--font-family-second);
}

.TitleBoxUp {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.05em;
  border-bottom: 0.833333px solid #000000;
  text-transform: uppercase;
  margin-top: 20px;
  width: 100%;
  font-family: var(--font-family-second);
}

.CloseBox {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 16px;
}

.ButtonConfirm {
  padding: 6px 16px;
  border: 1px solid #000000;
  border-radius: 96px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  cursor: pointer;
  font-family: var(--font-family-second);
}

.ButtonConfirm:hover {
  border: 1px solid #000000;
  box-shadow: 2px 2px 0px #000000;
  border-radius: 96px;
}

.ButtonConfirmDisabled {
  padding: 6px 16px;
  border: 1px solid #a0a0a0;
  border-radius: 96px;
  color: #a0a0a0;
  text-transform: uppercase;
  font-family: var(--font-family-second);
  letter-spacing: 0.05em;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.ButtonCancel {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  cursor: pointer;
  font-family: var(--font-family-second);
}

.Buttons {
  position: absolute;
  bottom: 20px;
  display: flex;
  width: calc(100% - 36px);
  justify-content: space-between;
  align-items: center;
}

.BoxModal {
  border: 1px solid #000000;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 18px;
  background-color: white;
  z-index: 2;
}


/* ANIMATE WITH SLIDING */

.BoxAnimation {
  transition: transform 350ms ease-in;
}
.BoxOpening {
  display: initial;
  transform: translateX(400px);
}
.BoxOpen {
  display: initial;
  transform: translateX(0);
}
.BoxClose {
  display: none;
  transform: translateX(0px);
}
.BoxClosing {
  display: initial;
  transform: translateX(400px);
}

/* ANIMATE WITH OPACITY */

/* .BoxAnimation {
  transition: opacity 350ms ease-in;
}
.BoxOpening {
  display: initial;
  opacity: 0;
}
.BoxOpen {
  display: initial;
  opacity: 1;
}

.BoxClosing {
  display: initial;
  opacity: 0;
}
.BoxClose {
  display: none;
  opacity: 0;
} */