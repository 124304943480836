.Question {
  font-family: var(--font-family-second);
  font-style: normal;
  font-weight: 400;
  font-size: 15xpx;
  line-height: 38px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-bottom: 1px solid #000000;
}

.Caption {
  margin-top: 22px;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Source Sans Pro";
  letter-spacing: 0.05em;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 4px;
  border-bottom: 1px solid black;
  margin-bottom: 5px;
}

.PreviewContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 90%;
  /* justify-content: space-between; */
}

.LogoWrapper {
  position: relative;
  border: 1px solid #a0a0a0;
  margin-bottom: 30px;
  margin-right: 4px;
  
}

.LogoActive {
  background-color: rgba(217, 217, 217, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.Check {
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid;
}

.Check.Active {
  content: url("../../assets/check.svg");
}

.LogoWrapper .Check {
  position: absolute;
  bottom: -20px;
  right: 0;
}

.SelectAll {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.SelectAllText {
  margin-left: 10px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.09em;
}

.MiniCamera {
  position: absolute;
  left: 5px;
  bottom: 5px;
  content: url('../../assets/mini-camera.svg');
}

.MiniCameraBlack {
  position: absolute;
  left: 5px;
  bottom: 5px;
  content: url('../../assets/mini-camera-black.svg');
}

@media only screen and (min-height: 150px) and (max-height: 600px) {

  .PreviewContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-height: 240px;
    overflow: auto;
    /* justify-content: space-between; */
  }

}

@media only screen and (min-height: 601px) and (max-height: 700px) {

  .PreviewContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-height: 340px;
    overflow: auto;
    /* justify-content: space-between; */
  }

}
