.FiltraPer {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  font-family: var(--font-family-second);
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.StarredChoiche {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  font-family: var(--font-family-second);
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #000000;
}

.FilterSelect {
  border-bottom: 1px solid #000;
  border-left: none;
  border-top: none;
  border-right: none;
  min-width: 130px;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  letter-spacing: 0.05em;

  color: #000000;
}

.FilterSelect:focus-visible {
    outline: none;
  }
