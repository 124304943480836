@media only screen and (min-width: 1000px) {
  .BodyDesktop {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ContainerDesktop {
    height: 100%;
    width: 100%;
    max-width: 1640px;
    position: relative;
  }

  .Left {
    position: absolute;
    left: 50px;
    height: 100%;
    z-index: 2;
    width: 342px;
  }

  .Center {
    position: absolute;
    left: 462px;
    right: 312px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Center.LogoCenterNoDomanda {
    left: 314px;
  }

  .Right {
    position: absolute;
    right: 50px;
    width: 312px;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .BoxText {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }

  .Button:hover > .TextButton {
    opacity: 1;
    margin-right: 16px;
  }

  .TextButton {
    opacity: 0;
    margin-right: 16px;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    font-family: var(--font-family-second);
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1150px) {
  .Left {
    position: absolute;
    left: 50px;
    width: 200px;
    height: 100%;
    z-index: 2;
  }
}

@media print {
  .Left {
    display: none;
  }
}

@media print {
  .Center {
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transform: scale(1.7);
    padding-top: 100px;
  }
}