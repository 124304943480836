.Question {
  font-family: var(--font-family-second);
  font-style: normal;
  font-weight: 400;
  font-size: 15xpx;
  line-height: 38px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-bottom: 1px solid #000000;
}

.Reset {
  cursor: pointer;
  margin-top: 74px;
  display: flex;
}

.ResetText {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.ResetIcon::after {
  margin-right: 10px;
  content: url('../../assets/reset.svg');
}

.SliderContainer {
  position: relative;
}

.SelectAgeText {
  position: absolute;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  left: 0;
  right: 0;
  letter-spacing: 0.09em;
  top: 27px;
}
