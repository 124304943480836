.CardLogin {
  border: 1px solid #000000;
  min-height: 474px;
  padding: 24px;
}

.Login {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  letter-spacing: 0.05em;
  text-decoration-line: underline;

  color: #000000;
  font-family: var(--font-family-second);
}

.InputLogin {
  margin-top: 48px;
  font-family: var(--font-family-second);
}

.LabelInput {
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  /* identical to box height, or 211% */
  width: 100%;
  font-family: var(--font-family-second);
  border-bottom: 1px solid #000;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 16px;

  color: #000000;
}

.PasswordDimenticata {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  letter-spacing: 0.05em;
  text-decoration-line: underline;
  font-family: var(--font-family-second);
  margin-top: 36px;
}

.ButtonConfirm {
  padding: 6px 16px;
  border: 1px solid #000000;
  color: #000;
  border-radius: 96px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
  background-color: white;
  line-height: 20px;
  letter-spacing: 0.05em;
  cursor: pointer;
  font-family: var(--font-family-second);
}

.ButtonConfirm:visited {
  color: #000;
}

.ButtonConfirm:hover {
  border: 1px solid #000000;
  box-shadow: 2px 2px 0px #000000;
  border-radius: 96px;
}

.ButtonConfirmDisabled {
  padding: 6px 16px;
  border: 1px solid #a0a0a0;
  border-radius: 96px;
  color: #a0a0a0;
  text-transform: uppercase;
  font-family: var(--font-family-second);
  letter-spacing: 0.05em;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.ButtonCancel {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  cursor: pointer;
  font-family: var(--font-family-second);
}

.SloganMilano {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  font-family: var(--font-family-second);
}

.SubSlogan {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  margin-top: 4px;
  font-family: var(--font-family-second);
}

@media only screen and (min-width: 150px) and (max-width: 1000px) {
  .CardLogin {
    margin-top: 0px;
    margin-left: 20px;
    min-height: 100%;
  }

  .Slogan{
    display: none;
  }
}
