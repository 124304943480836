.SubtitleBox {
  font-family: var(--font-family-second);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 38px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-bottom: 1px solid #000000;
}

.BoxPersonalization{
  position: absolute;
  bottom: 0px;
  background-color: white;
  z-index: 2;
  left: 0px;
  right: 0px;
  width: 100%;
  padding: 0px 20px 20px 20px;
}

.ButtonsPersonalization {
  position: absolute;
  bottom: 20px;
  display: flex;
  font-family: var(--font-family-second);
  width: calc(100% - 40px);
  justify-content: space-between;
  align-items: center;
}

.ButtonCancel {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  cursor: pointer;
}

.AgeRangeItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  margin-right: 20px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.12em;
  font-family: var(--font-family-second);
}

.ActiveAgeRange {
  background-color: white;
  padding: 3px;
  border: 1px solid #000;
  border-radius: 50%;
}

.AgeRange {
  padding: 3px;
  border: 1px solid transparent;
}

.ButtonNextStep {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 96px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  height: 38px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-family: var(--font-family-second);
}

.ButtonNextStepDisabled {
  background: #ffffff;
  border: 1px solid #a0a0a0;
  border-radius: 96px;
  color: #a0a0a0;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  height: 38px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-family: var(--font-family-second);
}

.ButtonConfirm {
  border: 1px solid #000000;
  border-radius: 96px;
  font-weight: 400;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
  padding-left: 16px;
  padding-right: 16px;
  height: 38px;
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 20px;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-family: var(--font-family-second);
}

.ButtonConfirmDisabled {
  border: 1px solid #a0a0a0;
  border-radius: 96px;
  color: #a0a0a0;
  font-weight: 400;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
  line-height: 20px;
  height: 38px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-family: var(--font-family-second);
}

.TitleBox {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
}

.DividerMobile {
  height: 1px;
  width: calc(100% + 22px);
  margin-left: -22px;
  background: black;
  position: absolute;
  z-index: 100;
}

.MaxLength {
  text-align: right;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.05em;
  font-family: var(--font-family-second);
}

.BoxAnimation {
  transition: transform 350ms ease-in;
}
.BoxOpening {
  display: initial;
  transform: translateY(400px);
}
.BoxOpen {
  display: initial;
  transform: translateY(0);
}
.BoxClose {
  display: none;
  transform: translateY(0px);
}
.BoxClosing {
  display: initial;
  transform: translateY(400px);
}
