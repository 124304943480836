.Extrem {
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.12em;
  font-family: var(--font-family-second);
}

.ValueSlider {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  font-family: var(--font-family-second);
  margin-left: -15px;
}

.HorizontalSlider {
  position: relative;
  width: 100%;
  height: 6px;
}

.FirstStepSlider {
  width: 36.84%;
  background: linear-gradient(90deg, #50ffff 0%, #7cfcc0 49.48%, #a8f980 100%);
  height: 6px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.SecondStepSlider {
  width: 36.84%;
  background: linear-gradient(
    89.95deg,
    #fff200 0.04%,
    #fcd208 48.96%,
    #ffc010 99.96%
  );
  height: 6px;
  position: absolute;
  top: 0px;
  left: 36.84%;
}

.ThirdStepSlider {
  background: linear-gradient(
    89.95deg,
    #f792ff 0.04%,
    #dc9aff 51.04%,
    #c1a1ff 99.96%
  );
  width: 26.32%;
  height: 6px;
  position: absolute;
  top: 0px;
  left: 73.68%;
}

.ThumbSlider {
  background: #ffffff;
  border: 1px solid #000000;
  height: 15px;
  width: 15px;
  margin-top: -5px;
  border-radius: 50%;
  cursor: pointer;
}

.ThumbSlider:focus {
  outline: none;
}
